body {
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif !important;
    font-weight: 500;
}

pre {
    font-family: 'Montserrat', sans-serif !important;
}

* {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.ReactModal__Overlay{
    z-index: 99 !important;
}

.form-control {
    position: relative;
    display: inline-flex;
    width: 100%;
    min-width: 0;
    padding: 4px 11px;
    font-weight: 500;
    color: rgb(0, 0, 0);
    font-size: 14px;
    line-height: 1.5;
    background-color: #ffffff;
    background-image: none;
    border-width: 1px;
    border-style: solid;
    font-size: 14px;
    border-color: #d9d9d9;
    border-radius: 6px;
    transition: all 0.2s;
}

.form-control:focus {
    box-shadow: none;
    outline: none;
    border: 1px solid #008aff;
}

.cursor-pointer {
    cursor: pointer;
}

.btn {
    border: 1px solid #d9d9d9;
    font-weight: 500;
    font-size: 14px;
    min-height: 32px;
    padding: 4px 15px;
    border-radius: 6px;
    background-color: #fff;
    box-shadow: 0 2px 0 rgba(0, 0, 0, .02);
}

.btn.disabled {
    opacity: 0.5;
    pointer-events: none;
}

.btn+.btn {
    margin-left: 5px;
}

.btn-link {
    border: none;
    color: #008aff;
    outline: none;
    padding: 0;
    font-size: 12px;
    box-shadow: none;
}

.btn-link:active,
.btn-link:focus,
.btn-link:visited {
    outline: none;
    box-shadow: none;
}

.btn-primary {
    color: #fff;
    background-color: #008aff;
    border-color: #008aff;
    box-shadow: none;
    margin-top: auto;
    letter-spacing: 0.5px;
}

.btn-secondary {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
}

.btn-primary a {
    color: #fff;
    text-decoration: none;
}

.popup-wrap-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2048;
}

.background-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #000;
    opacity: 0.6;
}

.popup-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    width: 50%;
    border-radius: 7px;
    overflow: hidden;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.popup-content-header {
    border-bottom: 1px solid #eee;
    background: #eee;
}

.popup-content-header h3 {
    font-size: 18px;
    padding: 10px;
    font-weight: 400;
}

.popup-content-header i {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

.popup-content-nav {
    border-bottom: 1px solid #eee;
}

.popup-content-nav.ird-nav {
    border-bottom: 1px solid #eee;
    display: flex;
    flex-direction: row;
}

.popup-content-nav .modal-header-list-item {
    flex: 1;
    text-align: center;
}

.popup-content-nav h3 {
    background: #bde8bd;
    margin: 0;
    padding: 10px 0px;
    font-size: 18px;
    font-weight: 300;
}

.popup-content-nav p {
    margin: 0;
    font-size: 10px;
    font-weight: 200;
    padding-top: 5px;
}

.popup-content-nav h4 {
    margin: 0;
    font-size: 12px;
}


.popup-content-card {
    border-bottom: 1px solid #eee;
    /* padding: 15px 0px; */
    padding: 15px;
}

.popup-content-card h3 {
    font-size: 16px;
    margin: 0;
    font-weight: 400;
    margin-bottom: 10px;
}

.popup-content-card p {
    font-size: 12px;
    margin: 0;
    line-height: 13px;
    font-weight: 300;
}

.popup-content-card p span {
    font-size: 12px;
    margin: 0;
    line-height: 13px;
    font-weight: 100;
}

.table-td-background {
    background: #bde8bd;
}

.primary-nav {
    padding: 15px 15px 15px 30px;
    background: #eee;
    min-height: 65px;
}

.primary-nav .right-menu {
    float: right;
}

.primary-nav .right-menu ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.primary-nav ul {
    list-style: none;
    margin: 0;
    padding: 0;
    float: left;
}

.primary-nav .right-menu ul li {
    float: left;
    margin-left: 10px;
}

.primary-nav .right-menu ul li span:first-child {
    padding: 5px 10px;
    border-radius: 30px;
}

.primary-nav .right-menu ul li i {
    cursor: pointer;
}

.secondary-nav {
    padding: 10px;
    background: #fff;
    margin-bottom: 10px;
    border-bottom: 1px solid #eee;
}

.card {
    overflow: hidden;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 15px;
}

.home-screen .col-md-2 {
    padding-left: 0;
    flex: 0 0 20%;
    max-width: 20%;
}

.home-screen .col-md-2:nth-child(5n) {
    padding-right: 0px;
}

.first-child-row {
    border-bottom: 1px solid #ccc;
}

.first-child-row h3 {
    /* background: #bde8bd; */
    font-size: 16px;
    padding: 10px;
    margin: 0;
}

.first-child-row p {
    margin: 0;
    text-align: center;
}

.first-child-row p.time {
    font-size: 9px;
    padding-top: 3px;
}

.first-child-row p.quantity {
    font-size: 13px;
}

.second-child-row {
    padding: 5px;
    border-bottom: 1px solid #ccc;
}

.second-child-row h3 {
    margin: 0;
    font-size: 10px;
    color: #3b5998;
}

.second-child-row p {
    margin: 0;
    font-size: 10px;
    text-align: center;
    line-height: 10px;
}

.third-child-row {
    background: #eee;
    border-bottom: 1px solid #ccc;
    padding: 5px;
}

.third-child-row h3 {
    margin: 0;
    font-size: 13px;
}

.third-child-row p {
    margin: 0;
    font-size: 13px;
    line-height: 13px;
    text-align: center;
}

.fourth-child-row {
    padding: 10px 15px;
    border-bottom: 1px solid #eee;
    height: 75px;
}

.fourth-child-row p {
    font-size: 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-bottom: 5px;
}

.fourth-child-row .more {
    font-size: 8px;
}

.fifth-child-row {
    background: #eee;
    padding: 5px;
    border-bottom: 1px solid #eee;
}

.fifth-child-row .heading {
    font-size: 10px;
}

.fifth-child-row .content {
    font-size: 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-bottom: 5px;
    height: 22px;
    margin: 0;
    padding-top: 5px;
}

.sixth-child-row {
    padding: 10px;
    text-align: center;
}

#inner-tab-nav {
    margin: 0 15px;
}

.tab-content {
    padding: 15px;
}

.blink {
    text-align: center;
    animation-delay: 0s;
    animation-name: blink;
    animation-duration: 1s;
    animation-iteration-count: infinite;
}

@keyframes blink {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}

.hidealert {
    -webkit-animation: seconds 1s forwards;
    -webkit-animation-iteration-count: 1;
    -webkit-animation-delay: 5s;
    animation: seconds 1s forwards;
    animation-iteration-count: 1;
    animation-delay: 5s;
}

@keyframes seconds {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        left: -9999px;
        position: absolute;
    }
}

.itemAddon {
    font-size: 20px;
    text-transform: uppercase;
    margin-top: 12px;
    color: #6f5f5f;
}

h3.price {
    font-weight: bold;
}

.separator {
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 40px;
    font-weight: bold;
}

.separator::before,
.separator::after {
    content: '';
    flex: 1;
    border-bottom: 2px solid #000;
}

.separator::before {
    margin-right: 0.25em;
}

.separator::after {
    margin-left: 0.25em;
}

.btn-outline-success {
    color: white;
    border-color: white;
    background: #28a745;
}

.btn-outline-success:hover {
    color: #28a745;
    border-color: #28a745;
    background: white;
}

.primary-nav .right-menu {
    width: 100%;
}

.newlogout {
    top: 1px !important;
}

.logout {
    top: 0px !important;
    right: 9px;
}

.newlogoutadmin {
    top: 18px !important;
    right: 9px;
}

.lastname_id_container {
    display: flex;
    justify-content: space-around;
}

.btn-group-sm>.btn,
.btn-sm {
    font-size: 0.75rem;
}

.filter-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 1rem;
}

.filter-btns {
    position: relative;
}

.showfilter {
    display: inline-flex;
    position: absolute;
    left: 0px;
    top: 100%;
    z-index: 1;
    width: 100%;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
}

.hidefilter {
    display: none;
}

.rdrCalendarWrapper.rdrDateRangeWrapper {
    display: none;
}

.rev {
    padding-top: 21px;
    padding-right: 40px;
}

.loader {
    color: #ffffff;
    font-size: 90px;
    text-indent: -9999em;
    overflow: hidden;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    margin: 72px auto;
    position: relative;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load6 1.7s infinite ease, round 1.7s infinite ease;
    animation: load6 1.7s infinite ease, round 1.7s infinite ease;
}

@-webkit-keyframes load6 {
    0% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
            0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }

    5%,
    95% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
            0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }

    10%,
    59% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em,
            -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em,
            -0.297em -0.775em 0 -0.477em;
    }

    20% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em,
            -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em,
            -0.749em -0.34em 0 -0.477em;
    }

    38% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em,
            -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em,
            -0.82em -0.09em 0 -0.477em;
    }

    100% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
            0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
}

@keyframes load6 {
    0% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
            0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }

    5%,
    95% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
            0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }

    10%,
    59% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em,
            -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em,
            -0.297em -0.775em 0 -0.477em;
    }

    20% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em,
            -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em,
            -0.749em -0.34em 0 -0.477em;
    }

    38% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em,
            -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em,
            -0.82em -0.09em 0 -0.477em;
    }

    100% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
            0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
}

@-webkit-keyframes round {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes round {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.popup-content-body {
    max-height: 700px !important;
}

.class-category {
    margin-left: 30px;
}

.class-sub-category {
    margin-left: 30px;
}

.class-category {
    margin-left: 30px;
}

.class-item {
    margin-left: 30px;
}

.class-addon {
    margin-left: 30px;
}

.class-sub-addon {
    margin-left: 30px;
}

.sub-category-item-heading {
    font-size: 1.1rem !important;
}

.heading-menu {
    font-size: 1.1rem !important;
}

h3.price {
    font-size: 1rem;
}

.showme {
    display: none;
    margin-right: 10px;
}

.showme.button-bottom-aligned {
    position: absolute;
    bottom: 7px;
    left: 7px;
}

.showme span {
    margin-left: 5px;
    margin-right: 5px;
}

.showhim:hover .showme {
    display: block;
}

.showhim.card {
    flex-direction: unset !important;
}

.rdrInputRanges {
    display: none;
}

/* add-edit css */

.main-container-wrap {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 0.75rem;
}

.categorry-wrapper {
    max-width: 350px;
}

.categorry-wrapper,
.item-container {
    display: flex;
    position: relative;
    width: 100%;
    flex-direction: column;
    max-height: 65vh;
    min-height: 65vh;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0px 0.5rem 0;
}

.main-items-wrapper {
    position: absolute;
    top: 0px;
    left: 20rem;
    width: 73%;
    padding: 10px;
    border-radius: 10px;
    max-height: 65vh;
    overflow-y: auto;
    overflow-x: hidden;
}

.subAddon-wrapper,
.addon-wrapper {
    margin-left: 3rem;
}

.items-wrapper,
.subaddon-card-wrapper,
.addon-card-wrapper {
    width: 100%;
    margin-bottom: 10px;
    cursor: pointer;
    box-shadow: 0 7px 14px 0 rgba(65, 69, 88, 0.1),
        0 3px 6px 0 rgba(0, 0, 0, 0.07);
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    background-color: #fff;
    border-radius: 0.375rem;
    /* min-height: 6rem; */
}

.items-wrapper.selected,
.subaddon-card-wrapper.selected,
.addon-card-wrapper.selected {
    border: 1px solid #199433;
}

.categorry-wrapper .btn {
    padding: 0.1375rem 0.5rem;
}

.order-delete-icon {
    margin-top: 0.35rem;
    border: none;
    outline: none;
    color: #828282;
    background: transparent;
    float: right;
    cursor: pointer;
}

/* add edit menu css */

.tabs-wrapper {
    overflow: auto;
    display: flex;
    flex-wrap: nowrap;
    padding: 10px 0;
    max-width: 77vw;
}

.tabs-wrapper .tabs {
    box-shadow: 0 7px 14px 0 rgba(65, 69, 88, 0.1),
        0 3px 6px 0 rgba(0, 0, 0, 0.07);
    border: 0 solid #edf2f9;
    margin-right: 10px;
    border-radius: 8px;
    padding: 0;
    min-width: 335px;
    height: 144px;
    cursor: pointer;
    color: #4d5969;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.tabs-wrapper .tabs.disabled::after,
.cat-card-wrapper.disabled::after,
.items-wrapper.disabled::after,
.subaddon-card-wrapper.disabled::after,
.addon-card-wrapper.disabled::after {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: #edf2f8a8;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #6f6f6fa8;
    pointer-events: none;
}

.tabs-wrapper .tabs.selectMenu .category-title {
    box-shadow: none;
    background-color: #199433;
    color: #ffffff;
}

.tabs-wrapper .tabs.tabs-add-new {
    background: #66615d;
    color: white;
    border-color: #66615d;
    padding: 5px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.add-new-tile {
    background-color: rgba(102, 97, 93, 1) !important;
    min-height: 4.5rem !important;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
}

.category-card {
    cursor: pointer;
}

.cat-card-wrapper {
    margin-bottom: 10px;
    cursor: pointer;
    position: relative;
    box-shadow: 0 7px 14px 0 rgba(65, 69, 88, 0.1),
        0 3px 6px 0 rgba(0, 0, 0, 0.07);
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0 solid #edf2f9;
    border-radius: 8px;
    min-height: 8rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.cat-card-wrapper.active .card-body1 {
    box-shadow: none;
    background-color: #199433;
    color: #ffffff;
}

.menu-card {
    box-shadow: 0 7px 14px 0 rgba(65, 69, 88, 0.1),
        0 3px 6px 0 rgba(0, 0, 0, 0.07);
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0 solid #edf2f9;
    border-radius: 0.375rem;
    width: 25rem;
    margin-bottom: 1rem;
    min-height: 11rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.sub-cat-wrapper {
    flex: 1 1;
    margin-bottom: 10px;
    cursor: pointer;
    box-shadow: 0 7px 14px 0 rgba(65, 69, 88, 0.1),
        0 3px 6px 0 rgba(0, 0, 0, 0.07);
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0 solid #edf2f9;
    border-radius: 0.375rem;
    min-height: 8rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.sub-cat-wrapper.selected {
    border: 1px solid #199433;
}

.card-header1 {
    /* background-color: #edf2f9!important; */
    padding: 0;
    margin-bottom: 0;
    border-bottom: 0 solid #edf2f9;
    border-radius: calc(0.375rem - 0px) calc(0.375rem - 0px) 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.card-body1 {
    flex: 1 1 auto;
    padding: 0.35rem 1rem;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex-direction: row-reverse;
    position: absolute;
    bottom: 0;
    width: 100%;
    background: #66615d;
    padding: 8px;
    border-radius: 8px;
    font-size: 14px;
    color: #ffffff;
    text-transform: capitalize;
    letter-spacing: 0.5px;
}

.text-view-only {
    padding: 0.35rem 1rem 0.35rem 0.35rem;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex-direction: row-reverse;
    width: 100%;
    background: transparent;
    border-radius: 5px;
    font-size: 14px;
    color: #000000;
    letter-spacing: 0.5px;
    min-height: 3.5rem;
    text-transform: capitalize;
}

.card-body1 img {
    max-width: 8rem;
    margin-right: 10px;
    border-radius: 8px;
    box-shadow: 0 7px 14px 0 rgba(65, 69, 88, 0.1),
        0 3px 6px 0 rgba(0, 0, 0, 0.07);
    height: auto;
    max-height: 8rem;
    object-fit: cover;
}

.category-title {
    display: block;
    text-decoration: none;
    font-weight: 400;
    font-size: 0.83333rem;
    color: #ffffff;
    margin: 0;
    position: absolute;
    bottom: 0;
    width: 100%;
    background: #66615d;
    padding: 8px;
    border-radius: 8px;
    letter-spacing: 0.5px;
}

.category-subtitle {
    font-weight: 400;
    line-height: 1.5;
    color: #5e6e82;
    text-align: left;
    font-size: 0.83333rem;
    margin: 0;
}

.btn-b {
    display: inline-block;
    padding: 0.5rem 0.65rem;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.42rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    color: #fff;
    cursor: pointer;
    text-align: left;
    margin-bottom: 5px;
    width: 100%;
    box-shadow: 0 0 0 1px rgba(43, 45, 80, 0.1),
        0 2px 5px 0 rgba(43, 45, 80, 0.08), 0 1px 1.5px 0 rgba(0, 0, 0, 0.07),
        0 1px 2px 0 rgba(0, 0, 0, 0.08);
}

.btn-toggle1 {
    border-radius: 5px;
    padding: 0px 8px;
    cursor: pointer;
    color: #4d5969;
    background-color: #fff;
    border-color: #fff;
    box-shadow: 0 0 0 1px rgba(43, 45, 80, 0.1),
        0 2px 5px 0 rgba(43, 45, 80, 0.08), 0 1px 1.5px 0 rgba(0, 0, 0, 0.07),
        0 1px 2px 0 rgba(0, 0, 0, 0.08);
    border: none;
    outline: none !important;
    position: absolute;
    top: 5px;
    right: 5px;
}

.btn-b .fa {
    margin-right: 5px;
}

.btn-b.btn-b-primary {
    background-color: #5b7dff;
}

.btn-b.btn-b-success {
    background-color: #5fc27e;
}

.btn-b.btn-b-danger {
    background-color: #f44455;
}

.btn-danger {
    background-color: #f44455;
    border-color: #f44455;
    color: #fff;
}

.btn-b.btn-b-warning {
    background-color: #fcc100;
}

.btn-b.btn-b-grey {
    background-color: #354052;
}

.btn-b-grey[aria-expanded='true'] .fa-plus-square:before {
    content: '\f146';
}

.btn-b-grey[aria-expanded='false'] .fa-plus-square:before {
    content: '\f0fe';
}

.dropdown-wrapper {
    position: relative;
}

div#myDropdown {
    display: none;
}

.dropdown-wrapper:hover div#myDropdown {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
    min-width: 90px;
    right: 0px;
    top: 23px;
    background: #eef3f9;
    border-radius: 10px;
    z-index: 9;
    box-shadow: 0 7px 14px 0 rgba(0, 0, 0, 0.17),
        0 3px 6px 0 rgba(0, 0, 0, 0.24);
    padding: 10px;
}

.card-items {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 10px;
    min-height: 6rem;
    font-size: 14px;
}

.has-customization {
    position: absolute;
    bottom: 0px;
    right: 0;
    padding: 2px 8px;
    background: #d0d0d0;
    color: #022c46;
    border-radius: 8px 0 5px 0;
    font-style: italic;
    font-size: 10px;
    letter-spacing: 0.25px;
    font-weight: 500;
}

.card-items .item-type-icon img {
    width: 20px;
    height: 20px;
    margin-right: 12px;
}

.card-items .item-name {
    flex: 1 1 auto;
}

.card-items .item-name .item-tags {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.card-items .item-name .item-tags .tags-tile {
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 1px;
    margin-right: 5px;
    background: #ff6a6a;
    border-radius: 4px;
    padding: 3px 8px;
    color: #fff;
    margin-bottom: 5px;
}

.react-dropdown-select-dropdown,
.react-dropdown-select-dropdown:focus {
    border: none !important;
}

.react-dropdown-select-dropdown>div {
    display: none;
}

/* width */
/* ::-webkit-scrollbar {
    width: 11px;
    height: 11px;
}

::-webkit-scrollbar-track {
    background: #dadada;
    border-radius: 12px;
}

::-webkit-scrollbar-thumb {
    background: #bbbbbb;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: #9c9c9c;
} */

.loader-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.56);
    z-index: 990;
}

.loader-wrapper {
    font-size: 34px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    border-radius: 10px;
    z-index: 9;
    width: 4rem;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.section-title {
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 1px;
    margin-right: 5px;
    background: #022c46;
    border-radius: 4px;
    padding: 3px 12px;
    color: #fff;
    align-self: flex-start;
    margin-bottom: 6px;
}

.manger-text {
    font-size: 22px;
    font-weight: 500;
    padding: 40px 0px 1px 1px;
}

.table tbody+tbody {
    border-width: 0;
}


.app-wrapper {
    display: flex;
    width: 100%;
    gap: 1.5rem;
    background-color: rgba(245, 248, 255, 1);
}

.main-layout {
    flex: 1 1 auto;
    padding-right: 1rem;
    min-height: 100vh;
    padding-left: 18rem;
    transition: 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
    /* background-image: url('../src/assets/images/new-year.gif');
    background-repeat: no-repeat;
    background-origin: content-box;
    background-size: 40%;
    background-position: top center; */
}

.main-layout.collapsed-layout {
    padding-left: 5rem;
}

.action-header {
    display: flex;
    justify-content: space-between;
    margin: 1.25rem 0;
    align-items: center;
}

.page-title {
    font-size: 1.1rem;
    font-weight: 500;
    margin-bottom: 0.25rem;
}

.scheduler-header{
    display: flex;
    flex-direction: column;
    margin: 1.25rem 0;
}

/* 
input[type='radio']{
    appearance: none;
    width: 0.65rem;
    height: 0.65rem;
    outline: 1px solid #a9a9a9;
    outline-offset:2px;
    border-radius: 50%;
    margin-right: 10px;
}
input[type='radio']:checked{
    background-color: #1677ff;
} */