.inner_table {
    border-radius: 8px;
    border-collapse: unset;
    width: 100%;
    margin: 5px 0;
    border: 1px solid #cfe1e8;

    .booking-header {
        padding: 5px;
    }

    td {
        border-top: none;
    }

    tr {
        box-shadow: none !important;
    }

    ol {
        border-top: 1px solid #cfe1e8;
        text-align: left;

        li {
            padding: 0px;
            color: #222;
            text-decoration: none;
        }
    }
}

.modal-transition {
    &.modal-dialog {
        align-items: center;
        max-width: 480px;
        margin: 0 auto;
    }
}
.transtion-loader {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 100%;
    min-height: 16rem;
    position: relative;
    z-index: 1;
    &::after {
        content: '';
        position: absolute;
        top: 42%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 10rem;
        border-top: 1px dashed #c1c1c1;
        z-index: -1;
    }
    .state {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 0.5rem;
        .label {
            font-size: 15px;
            margin-top: 1rem;
            font-weight: 400;
            color: #121b2b;
        }
    }
    .state-circle {
        width: 7rem;
        height: 7rem;
        border: 1px solid #ececec;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        img {
            width: 100%;
            max-width: 2.5rem;
            &.placeholder-img {
                // filter: opacity(0.5) grayscale(1);
            }
            &.transition-img {
                animation: mymove 2s infinite;
                position: absolute;
            }
        }
    }
}
@keyframes mymove {
    0% {
        transform: translateX(0%);
    }

    100% {
        transform: translateX(560%);
    }
}
.loading-wrapper {
    width: 100%;
    min-height: 50vh;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

// notification scss

.notifiction-wrapper {
    position: fixed;
    bottom: 70px;
    right: 20px;
    z-index: 999999;
    top: unset;
    width: 250px;
    min-height: 100px;
    background: #fff;
    // box-shadow: rgba(0, 0, 0, 0.33) 0px 0px 8px 0px;
    box-shadow: rgba(37, 117, 252, 0.2) 0px 0px 8px 0px;
    border-radius: 8px;
    background: #fddfe3;
    padding: 1rem;
    animation: move 3.5s infinite ease-in-out;
    .btn {
        padding: 0.25rem;
        color: #fff;
        background-color: #28a745;
        border-color: #28a745;
        width: 100%;
        font-size: 0.75rem;
        font-weight: 400;
    }
    .noti-title {
        font-size: 18px;
        font-weight: 400;
        color: #000;
        line-height: 1.5;
        margin-bottom: 0.5rem;
        .fa {
            font-size: 1rem;
            color: #fc3838;
            padding: 7px;
            background: #fff;
            border-radius: 22px;
            border: 1px solid red;
            margin-right: 5px;
        }
        span {
            font-weight: 600;
            display: inline-block;
            margin-bottom: 0.25rem;
            font-size: 0.85rem;
            line-height: 1.5;
        }
    }
}
@keyframes move {
    // 20%,
    // 100% {
    //   transform: translate3d(0px, 0, 0);
    // }
    // 0% {
    //   transform: translate3d(-1px, 0, 0);
    // }
    // 5% {
    //   transform: translate3d(1px, 0, 0);
    // }
    // 10% {
    //   transform: translate3d(-4px, 0, 0);
    // }
    // 15% {
    //   transform: translate3d(4px, 0, 0);
    // }
    // 20% {
    //   transform: translate3d(0px, 0, 0);
    // }
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}

.filter-text {
    font-size: 0.75rem;
}
.dot-badge {
    display: inline-block;
    border-radius: 50%;
    position: absolute;
    top: 60%;
    transform: translateY(-50%);
    right: 1.85rem;
    .fa {
        font-size: 1.25rem;
        color: #fc3838;
        -webkit-animation-name: animateBell;
        -webkit-animation-duration: 5s;
        -webkit-animation-iteration-count: infinite;
        -webkit-transform-origin: 50% 0;
        -webkit-animation-timing-function: ease;
    }
}
.order-list-container {
    position: relative;
    min-height: 20vh;
    padding-bottom: 4rem;
}
@keyframes animateBell {
    0%,
    100% {
        transform: rotate(25deg);
    }
    10%,
    90% {
        transform: rotate(-25deg);
    }
    40%,
    90% {
        transform: rotate(25deg);
    }
    50% {
        transform: rotate(-25deg);
    }
}
.image-wrapper {
    position: relative;
    .image {
        width: 240px;
        height: 120px;
        border-radius: 8px;
        object-fit: cover;
    }
    .btn-icon {
        position: absolute;
        top: 10px;
        left: 10px;
        font-size: 16px;
        padding: 6px 12px;
    }
}

.home-screen {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
}

.sidebar-menu-icon {
    width: 20px;
    height: 20px;
    margin-right: 7px;
    filter: invert(1);
    object-fit: contain;
}
.logout-wrapper {
    position: relative;
    margin-top: auto;
    text-align: center;
    display: block;
    color: #dcdcdc;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 0.5rem;
    padding: 0 1rem;
    .logout-container {
        display: flex;
        align-items: center;
        font-size: 0.75rem;
        color: #166fd4;
        cursor: pointer;
    }
    .toggle-button {
        cursor: pointer;
    }
    span {
        margin-left: 0.5rem;
    }
}
.overlay-loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000000db;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    z-index: 9;
    p {
        font-size: 14px;
        font-weight: 400;
        line-height: 1.5;
        color: #fff;
    }
}

.sidebar-wrapper {
    background-color: #fff;
    width: 16rem;
    background-color: #001529;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    position: fixed;
    transition: 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
    .hotel-name {
        text-align: center;
        margin: 1rem 0 1.25rem;
        font-size: 1.25rem;
        font-weight: 500;
        padding: 0 1rem;
        color: #fff;
        text-align: center;
    }
    .ant-menu {
        width: 250px;
    }
    &.collapsed {
        width: 3rem;
        .ant-menu {
            width: 40px;
        }
        .hotel-name {
            font-size: 0.5rem;
            padding: 0rem 0.5rem;
            min-height: 4rem;
            margin-bottom: 0;
        }
        .logout-wrapper {
            flex-direction: column;
            gap: 1rem;
            .logout-container {
                flex-direction: column;
                span.logout-text {
                    display: none;
                }
            }
        }
    }
}
.recommendedWrapper{
    padding: 12px;
    border: 1px dashed #000000;
    border-radius: 8px;
    .label {
        font-size: 13px;
        font-weight: 400;
        color: #121b2b;
    }  

    .searchWrapper{
        min-height: 5rem;
        input{
            width: 100%;
        }
        .icon_down_dir{
            top: 1.3rem;
            font-size: 10px;
            background-image: url('data:image/svg+xml,\00003csvg focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">\00003cpath fill="%239aa0a6" d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z">\00003c/path>\00003c/svg>');
            display: inline-block;
            height: 20px;
            width: 20px;
            filter: brightness(0.5);
            &::before,svg{
                display: none;
            }
        }
    }
}
.pagination-wrapper{
    display: flex;
    justify-content: center;
    position: fixed;
    bottom: 0;
    background-color: rgba(245, 248, 255, 1);
    width: 90%;
    padding: 1rem;
}