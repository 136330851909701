.status-tabs {
//   background-color: rgba(242, 245, 254, 1);
//   padding-top: 1.5rem;
  position: relative;
  .nav-link {
    display: block;
    padding: 0.5rem 1rem;
    font-size: 14px;
    font-weight: 400;
    color: #000;
    user-select: none;
  }
  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    border-color: transparent transparent #fff;
    font-weight: 500;
    color: #ffffff;
    background: #1877f273;
    border-bottom-width: 1px;
  }
  .nav-tabs .nav-link:focus,
  .nav-tabs .nav-link:hover {
    border-color: transparent transparent #000;
    font-weight: 500;
    color: #121b2b;
    letter-spacing: -0.2px;
    border-bottom-width: 2px;

  }
}
.search-box {
    position: absolute;
    top: 1rem;
    right: 1.8rem;
    input{
        min-width: 21rem;
        border-radius: 50px;
        font-size: 14px;
        padding: 14px;
        width: 100%;
        border: 1px solid #e5e5e5;
        font-size: 14px;
        color: #121b2b;
        outline: 0;
        letter-spacing: .5px;
        background: #fff;
        box-shadow: none;
    }
    .clear-icon{
      position: absolute;
      top: 8px;
      right: 12px;
      cursor: pointer;
    }
}