.report_wrapper {
  min-height: calc(100vh - 55px);
  .aside_main_wrapper {

    .filter-type-option {
        width: 100%;
      label {
        font-size: 0.7rem;
        margin-bottom: 0px;
      }
      select,input {
        font-size: 12px;
        width: 100%;
        outline: 0;
        letter-spacing: 0.5px;
        box-shadow: none;
        border: 1px solid #cfe1e8;
        padding: 6px 10px;
        border-radius: 8px;
        font-weight: 500;
        background: #fff;
        min-height: 35px;
        width: 100%;
      }
 
    }

  }
  .reports_main {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;

    .home-order-screen {
      background: #ffffff;
      margin: 10px 0;
      border-radius: 8px;
    //   box-shadow: rgba(37, 117, 252, 0.2) 0px 0px 5px 0px;
      border: 1px solid #f0f0f0;
      display: flex;
      align-items: flex-start;
      text-align: center;
      justify-content: center;
      padding: 0.75rem 0;
      min-height: 22rem;
      height: auto;
      width: 100%;
      &:nth-child(2) {
        width: 64%;
      }
      &:nth-child(3) {
        width: 35%;
      }

      tr th {
        font-weight: 600;
        font-size: 13px;
        color: #121b2b;
        border: none;
        padding: 1rem 0.5rem;
      }
      tr td {
        font-weight: 400;
        font-size: 13px;
        color: #121b2b;
        border: none;
        padding: 0.5rem;
        text-align: center;
      }
    }
  }
}
