.m-enable-wrapper {
    margin-top: 1rem;

    &.ird_menu {
        display: flex;
        align-items: flex-start;
        .m-wrapper {
            position: relative;
            min-width: 350px;
            min-height: 600px;
            padding: 0 1rem;
            .url-hotel-input {
                border-radius: 4px;
                font-size: 12px;
                padding: 10px;
                width: 100%;
                border: 1px solid #e5e5e5;
                color: #000;
                outline: 0;
                letter-spacing: 0.5px;
                background: #fff;
                box-shadow: none;
                min-height: 42px;
                margin-bottom: 0.5rem;
            }
            .mobile-view {
                // background-image: url("../../assets/images/m-frame.png");
                width: 320px;
                height: 568px;
                display: flex;
                align-items: center;
                position: relative;
                background-size: 100% 100%;
                justify-content: center;
                margin: auto;
                iframe {
                    position: absolute;
                    top: 15px;
                    left: 14px;
                    border-radius: 25px;
                    border: none;
                }
            }
        }
    }

    .m-card {
        margin-bottom: 15px;
        cursor: pointer;
        position: relative;
        // box-shadow: 0 7px 14px 0 rgba(65, 69, 88, 0.1),
        //   0 3px 6px 0 rgba(0, 0, 0, 0.07);
        display: flex;
        flex-direction: column;
        background-color: #fff;
        background-clip: border-box;
        border: 1px solid #cacaca;
        border-radius: 8px;
    }
    .m-title {
        padding: 10px;
        background: #f1f1f1;
        border-radius: 8px;
        font-size: 15px;
        font-weight: 500;
        padding-right: 5px;
        display: flex;
        justify-content: space-between;
    }
    .m-title-plain {
        padding: 5px;
        background: #f7f7f7;
        border-radius: 8px;
    }
    .m-desp {
        font-size: 13px;
        margin: 0;
        font-weight: 400;
        margin-bottom: 10px;
        color: #696969;
    }
    .m-price {
        padding: 0px 0;
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 5px;
        margin-top: 5px;
    }
    .m-item-card {
        margin: 7px 0;
        cursor: pointer;
        position: relative;
        box-shadow: 0 3px 7px 0 rgba(65, 69, 88, 0.1),
            0 1px 3px 0 rgba(0, 0, 0, 0.07);
        background-color: #fff;
        border-radius: 8px;
        padding: 10px 12px;
        border: 1px solid #001529;
        border-style: dashed;
        background: rgba(245, 248, 255, 1);
       
        &:last-child {
            margin-bottom: 0;
        }
    }
    .m-content-addon {
        display: flex;
        justify-content: space-between;
        padding: 6px 12px;
        border: 1px solid #eeeeee;
        background: rgba(245, 248, 255, 1);
        border-radius: 8px;
        margin-bottom: 0.5rem;
        span img{
            margin-right: 5px;
        }
    }
    .m-content-card {
        // border-bottom: 1px solid #eee;
        padding: 5px;
        margin-left: 10px;
        &:last-child {
            border-bottom: none;
        }
    }
    .card-body {
        background: #f6f9fd;
        flex: 1 1 auto;
        min-height: 1px;
        padding: 1.25rem;
    }
}

.itemname-wrapper {
    display: flex;
    justify-content: space-between;
    span img{
        margin-right: 5px;
    }
}
