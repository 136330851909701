.wrapper-bookings .home-order-screen {
  margin-top: 1rem;
}

.filter-container {
  &.bookings{
    .left-box,
    .right-box {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  &.bookings {
    .date-picker {
      outline: none;
      background: #fff;
      border-radius: 10px;
      font-weight: 500;
      padding: 9px 7px;
      font-size: 12px;
      box-sizing: border-box;
      text-align-last: center;
      margin: 0px 10px;
      border: 1px solid #cfe1e8;
      min-width: 12rem;
      text-transform: capitalize;
      text-align: left;
    }
    .booking-header {
      outline: none;
      background: #fff;
      border-radius: 8px;
      font-weight: 500;
      padding: 5px;
      font-size: 11px;
      box-shadow: 0 2px 3px 0 rgba(65, 69, 88, 0.1),
        0 1px 3px 0 rgba(0, 0, 0, 0.07);
      box-sizing: border-box;
      text-align: center;
      text-align-last: center;
      margin: 0px 0;
      border: 1px solid #d0d0d0;
      min-width: 5rem;
      text-align: left;
      display: inline-block;
      margin-right: 10px;
      cursor: pointer;
      text-transform: capitalize;
      position: relative;

      &.active {
        background: #beedd7;
        color: #000000;
        border: 1px solid #4f8d6a;

        .orderCount {
          // display: block;
        }
      }

      .orderCount {
        position: absolute;
        top: -3px;
        right: -3px;
        background: #ffb400;
        width: auto;
        height: 13px;
        font-size: 10px;
        color: #fff;
        padding: 0px 4px;
        line-height: 12px;
        border-radius: 4px;
        display: none;
      }
    }
  }
}
