.result_revenue {
    margin-top: 4rem;
    .result_title {
      text-align: center;
      font-size:  0.8rem;
      span {
        font-weight: 600;
        display: block;
        font-size: 1.2rem;
      }
    }
  }
  