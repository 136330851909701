.bg-grey{
  background-color: rgba(242, 245, 254, 1);
  position: relative;
}

.order-history-wrapper {
//   background-color: rgba(242, 245, 254, 1);
  position: relative;
//   min-height: calc( 100vh - 55px);
  display: flex;


  .home-order-screen {
    background: #ffffff;
    margin: 8px 0 10px;
    border-radius: 8px;
    // box-shadow: rgba(37, 117, 252,  .2) 0px 0px 5px 0px;
    height: calc(100vh - 176px);
    display: flex;
    overflow: auto;
    align-items: flex-start;
    border: 1px solid #f0f0f0;
    .table{
      margin: 0;
      thead{
        position: sticky;
        top: 0px;
        background: #fff;
    
      }
    }

  }
  .table-history thead tr th {
       font-weight: 600;
    font-size: 11px;
    color: #121b2b;
    border: none;
    padding: 1rem 0.5rem;
  }
  .table-history tbody {
    tr:not(:last-child) {
      border-bottom: 1px solid #ececec;
      cursor: pointer;
    }
  }
  .table-history tbody tr td {
    font-weight: 400;
    font-size: 11px;
    color: #121b2b;
    border: none;
    padding: 0.5rem;
    text-align: center;
  }
  .type-tile {
    background: #decda2;
    color: #121b2b;
    border-radius: 22px;
    padding: 3px 14px;
    margin-left: 10px;
    font-weight: 500;
    font-size: 12px;
    &.delivery {
      background: #d3daff;
    }
    &.preOrder{
      background: #f44336;
      color: #ffffff;
      border-radius: 4px;
    }
  }
  .table td,
  .table th {
    padding: 1.1rem;
  }
}
