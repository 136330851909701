.top-nav {
  background: #fff;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.1); */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
  box-shadow: 0 1px 0 0 #dde0e4;
  min-height:55px;
  .hotelName {
    font-size: 21px;
    margin-bottom: 0.5rem;
    font-weight: 600;
    margin: 0;
    color: #121b2b;
    line-height: 28px;
  }
  .menuLinks {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style-type: none;
    .nav-item {
      margin: 0 15px;
      position: relative;
      .nav-link {
        margin-bottom: 0.5rem;
        padding: 15px 10px;
        display: inline-block;
        margin: 0;
        text-decoration: none;
        margin: 0;
        position: relative;
        text-transform: capitalize;
        font-size: .875rem;
        font-weight: 500;
        line-height: 25px;
        color: #4a4a4a;
        text-transform: uppercase;
        &.active {
          color: #0059c1;
          &::after {
            content: "";
            position: absolute;
            left: 0;
            bottom: -1px;
            width: 100%;
            height: 1px;
            background: #0059c1;
          }
        }
      }
      .logout-btn {
        background-color: #121b2b;
        width: 30px;
        border-radius: 30px;
        height: 30px;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 13px;
        line-height: 23px;
        box-shadow: 0px 0px 3px #00000063;
        cursor: pointer;
      }
    }
  }
}
// dropdown css
.dropdown {
  position: absolute;
  left: 50%;
  transform: translateX(-50%) translateY(20px);
  transition: all 0.3s ease;
  margin-top: 3px;
  list-style-type: none;
  padding: 0;
  width: 11rem;
  max-width: 15rem;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  visibility: hidden;
  opacity: 0;

  &::after {
    content: "";
    position: absolute;
    width: 15px;
    height: 15px;
    background: #fff;
    transform: rotate(45deg);
    left: 50%;
    transform: rotate(45deg) translateX(-50%);
    z-index: -1;
  }
  li {
    margin: 0px;
    a {
      padding: 0.45rem 1rem;
      font-size: 13px;
      font-weight: 400;
      color: #757575;
      -webkit-user-select: none;
      user-select: none;
      width: 100%;
      display: inline-block;
      text-decoration: none;
      transition: all 0.5s ease;
    }
    &:hover {
      background: #f3f3f3;
      a {
        font-weight: 500;
        color: #000;
        transform: translateX(5px);
      }
    }
  }
}
.nav-item:hover > .dropdown {
  visibility: visible;
  opacity: 1;
  transform: translateX(-50%) translateY(0);
}
