// .login-page {
//   display: flex;
//   min-height: 100vh;
//   background: #f9fafc;
//   .login-title {
//     text-align: left;
//     color: #1b1464;
//     font-size: 22px;
//     letter-spacing: 0.4px;
//     font-weight: 300;
//     span {
//       font-weight: 700;
//     }
//   }
//   .login-subtitle {
//     font-size: 13px;
//     font-weight: 300;
//     margin-bottom: 3rem;
//     color: #1b1464;
//   }
//   .login-wrapper {
//     min-width: 30rem;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     background: #f4f9fe;
//     position:relative;
//   }
//   .form-group {
//     margin-bottom: 1.5rem;
//   }
//   .login-wrap {
//     width: 100%;
//     max-width: 330px;
//     margin-top:5rem;
//     .error-msg {
//       width: 100%;
//       font-size: 80%;
//       color: #dc3545;
//       text-align: center;
//       margin: 8px 0;
//     }
//   }
//   input {
//     display: block;
//     width: 100%;
//     height: 2.65rem;
//     padding: 0.5rem 1rem;
//     font-size: 0.9rem;
//     font-weight: 400;
//     line-height: 1.5;
//     color: #4a5568;
//     background-color: #fff;
//     background-clip: padding-box;
//     border: 1px solid #1b1464;
//     border-radius: 10px;
//     transition: all 0.2s ease;
//     outline: none;
//     caret-color: #1b1464;
//     box-shadow: 0px 1px 3px #00000024;
//   }
//   .btn-login {
//     height: 2.65rem;
//     width: 100%;
//     border-radius: 10px;
//     background-color: #1b1464;
//     border-color: #1b1464;
//   }
// }

// .lottie-wrapper {
//   width: 100%;
//   background: #1b1464;
//   .player-box {
//     max-width: 40rem;
//     max-height: 40rem;
//     margin: auto;
//   }
// }
// .logo-header {
//   position: absolute;
//   top: 2.5rem;
//   left: 50%;
//   transform: translateX(-50%);
//   img {
//     max-width: 10rem;
//   }
// }

.login-page {
  background-color: #f9fbfd;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  .login-wrapper {
    max-width: 24rem;
    width: 100%;

    .app-logo {
      background-image: url("https://viralops.com/wp-content/uploads/2021/01/3.png");
      width: 70px;
      height: 100px;
      background-repeat: no-repeat;
      background-size: contain;
      margin: auto;
      display: block;
      margin-bottom: 1.5rem;
    }

    .login-title {
      .title {
        text-align: center;
        color: #1b1464;
        font-size: 22px;
        letter-spacing: 0.4px;
        font-weight: 600;
        margin-bottom: 5px;
      }

      .subtitle {
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 3rem;
        color: #1b1464;
        margin-bottom: 1rem;
      }
    }

    .login-box {
      border: 1px solid #e3ebf6;
      margin: 10px 0;
      background-color: #fff;
      border-radius: 8px;
      box-shadow: 0 0 5px 0 rgba(37, 117, 252, 0.2);
      display: flex;
      overflow: auto;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 1rem;
      .form-control {
        margin-bottom: 15px;
        min-height: 40px;
        border-radius: 8px;
        font-size: 14px;
      }
      .btn {
        display: flex;
        align-items: center;
        width: 8rem;
        text-align: center;
        justify-content: center;
        margin-top: 1rem;
        i {
          margin-right: 5px;
          font-size: 0.85rem;
        }
      }
      .or {
        margin: 0.5rem 0 0;
        text-align: center;
        color: #1b1464;
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
}
