.home-order-screen {
  .table-order-inner {
    margin-bottom: 0;
    thead tr {
      background: transparent;
    }
  }
  .expandable-div {
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.14);
    position: relative;
    &::after {
      content: "";
      position: absolute;
      top: -10px;
      right: 90px;
      border-width: 20px;
      width: 10px;
      height: 10px;
      border-left: 10px solid transparent;
      border-bottom: 10px solid #efefef;
      border-right: 10px solid #0000;
    }
  }

  &.no-data {
    justify-content: center;
    align-items: center;
  p{
    margin: auto;
  }
  }
}
