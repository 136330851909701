.aside-left {
  width: 13rem;
  min-width: 13rem;
//   background: #ffffff;
//   box-shadow: rgba(37, 117, 252, 0.2) 0px 0px 5px 0px;
//   position: fixed;
//   height: 100%;
//   left: 0;
//   top: 50px;
  .aside_title {
    font-size: 1rem;
    margin: 0 0 0 1rem;
    text-align: center;
  }
  .aside_main_wrapper {
    display: flex;
    padding-left: 1rem;
    flex-direction: column;
    .dates_wrapper {
      display: flex;
      width: 100%;
      flex-direction: column;
      .form_group {
        display: flex;
        flex-direction: column;
        margin-bottom: 1rem;
        label {
          font-size: 0.7rem;
        }
        input {
          border-radius: 4px;
          font-size: 12px;
          padding: 10px 10px;
          width: 100%;
          border: 1px solid #e5e5e5;
          color: #000000;
          outline: 0;
          letter-spacing: 0.5px;
          background: #fff;
          box-shadow: none;
        }
      }
    }
  }
}
.aside-right {
  width: 100%;
//   padding-left: 320px;
}
.aside-left {
  .filter-type-option {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    position: relative;

    .datemain-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .remove-icon {
        width: 28px;
        height: 25px;
        border: 2px solid #757575;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 22px;
        margin-left: 5px;
        font-size: 12px;
        cursor: pointer;
      }
    }

    &.date-filter {
      .show-filter {
        display: block;
        width: 100%;
        height: 2.65rem;
        padding: 0.5rem 1rem;
        font-size: 0.8rem;
        font-weight: 400;
        line-height: 1.5;
        background: #f5f7f8;
        border: 0.1rem solid #ccc;
        cursor: pointer;
        background-clip: padding-box;
        border: 1px solid transparent;
        border-radius: 0.375rem;
        transition: all 0.2s ease;
        outline: none;
        caret-color: #008aff;
      }
    }
  }

  .filtertype-label {
    color: #222222;
    font-size: 0.875rem;
    font-weight: 500;
    text-transform: none;
    margin-bottom: 3px;
  }
  .filter-type-option {
    label {
      font-size: 0.7rem;
    }
    input {
      border-radius: 4px;
      font-size: 12px;
      padding: 10px;
      width: 100%;
      border: 1px solid #e5e5e5;
      color: #000;
      outline: 0;
      letter-spacing: 0.5px;
      background: #fff;
      box-shadow: none;
      min-height: 42px;
      cursor: pointer;
    }
  }

  .btns-wrapper {
    display: flex;
    justify-content: center;
    margin-top: auto;
    flex-direction: column;
gap: 0.5rem;
    .btn {
      width: 100%;
      margin-left: 0;
      &.btn-submit{
         margin-top: 10px
     }
    }
  }
}

.download-excel {
  border: 1px solid #cfe1e8;
  font-size: 13px;
  padding: 7px 15px;
  border-radius: 8px;
  font-weight: 500;
  background: #fff;
}
.pagination-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 0;
  ul {
    margin: 0 auto;
  }
  .form-group {
    margin: 0;
    margin-right: 2rem;
    label {
      font-weight: 400;
      font-size: 13px;
    }
    select {
      width: 3rem;
      border-radius: 0;
      outline: none;
      padding: 0;
      font-size: 0.9rem;
      font-weight: 400;
      border: none;
      transition: all 0.2s ease;
      margin-left: 15px;
      background: transparent;
    }
  }
}
