.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.64);
  overflow: auto;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem 0;
  pointer-events: none;
  display: flex;
  align-items: flex-start;
  min-height: calc(100% - 0.5px);
  margin: 1.75rem auto;
  outline: none;

  max-width: 962px;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  outline: 0;
  background-clip: border-box;
  border: none;
  border-radius: 0;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2);
}

.f-d-c {
  flex-direction: column !important;
}

.f-d-cr {
  flex-direction: column-reverse !important;
}

.f-d-r {
  flex-direction: row-reverse !important;
}

.f-d-r {
  flex-direction: row !important;
}

.TA-c {
  text-align: center !important;
}

.f-c {
  justify-content: center !important;
  align-items: center !important;
}
.modal-body {
  padding: 0.5rem 1rem;
  background-color: #fff;
}
.modal-header {
  background: #f0f2f5;
  align-items: center;
  padding: 1rem;
  .title {
    color: #484848;
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 0.2px;
    text-transform: capitalize;
  }
}
.modal-footer{
    padding: 1rem;
}
.modal-md {
  max-width: 562px;
}
.modal-order-details {
  .details-div {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 0.5rem;
    border-bottom: 1px solid #cfe1e8;
    padding-bottom: 0.5rem;

    &:last-child {
      border-bottom: none;
    }
  }

  .modal-details-sec {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    text-align: left;
    width: 100%;
    .details-label {
      color: #222222;
      font-size: 0.775rem;
      font-weight: 500;
      text-transform: none;
      margin-bottom: 3px;
      text-transform: uppercase;
    }

    .details-value {
      color: #191919;
      font-weight: 600;
      font-size: 12px;
      .main-column {
        display: flex;
        flex-wrap: wrap;
        padding: 0;
        padding-left: 10px;
        list-style: none;
        margin: 0;
      }
      .main-item {
        width: calc(100% / 3);
        font-size: 12px;
        border-right: 1px solid #cfe1e8;
        padding: 0 10px;
        border-bottom: 1px solid #cfe1e8;
        border-color: #000;
        &:nth-child(3n) {
          border-right: none;
        }
      }

      li {
        font-weight: 500;
        font-size: 10px;
        font-weight: 500;
      }
    }
    &.order-item {
      .details-value {
        width: 100%;
      }
    }
  }
}
.modal-addEdit-item {
  .accordian-card {
    .accordian-subaddons {
      font-size: 13px;
      font-weight: 400;
      margin-bottom: 5px;
      span {
        cursor: pointer;
        margin-left: 0.5rem;
      }
    }
    .accordian-addons {
      font-size: 12px;
      font-weight: 300;
      margin-bottom: 5px;
      padding-left: 1.5rem;
    }
  }
}
