.orderCard {
  width: calc(100% / 5 - 14px);
  max-width: 15rem;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: rgba(37, 117, 252, 0.2) 0px 0px 8px 0px;
  display: flex;
  flex-direction: column;
  border-top: 5px solid #ee8e94;
  position: relative;
  .preOrder {
    background-color: #f44336;
    color: #ffffff;
    font-size: 12px;
    border-radius: 3px;
    position: absolute;
    left: 5px;
    top: -10px;
    padding: 4.2px 10.4px;
    line-height: normal;
  }
  &.takeaway {
    border-top-color: #66615d;
  }
  .line {
    display: block;
    width: 100%;
    height: 1px;
    background: #dadada;
  }

  .orderCard-row {
    display: flex;
    justify-content: space-between;

    &.redbg {
      background: #fddfe3;
    }

    &.greybg {
      background: #f9f5f5;
    }
    .orderedItems {
      min-height: 66px;
      max-height: 66px;
      overflow: hidden;
      justify-content: flex-start;
    }
  }

  .text {
    font-size: 13px;
    padding: 5px;
    flex: 1 1;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;

    &.left {
      justify-content: center;
      align-items: flex-start;
    }
  }

  .card_action {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: auto;
    padding: 8px 5px;
    flex-wrap: wrap;
    .view-details {
      text-decoration: none;
      color: #4285f4;
      padding: 0;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 12px;
      margin-bottom: 5px;
      letter-spacing: 0.75px;
    }
    .btn:not(.btn-link) {
      font-size: 11px;
      padding: 7px 8px;
    }
    .btn-action {
      color: #0b1521;
      background-color: #ffffff;
      border-color: #eaeaea;
      box-shadow: none;
      border-radius: 0.375rem;
      font-weight: 500;
      padding: 4px 15px;
      box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.15);
    }
  }
}
.fd-D {
  flex-direction: column;
}
.text-align-L {
  text-align: left;
}
