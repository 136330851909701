.pagination {
    margin: 1rem auto 2rem;

    li {

        margin: 0 2px;

        a {
            outline: none;
            color: #131221;
            font-size: 16px;
            font-weight: 500;
            text-transform: capitalize;
            padding: 0 10px;
            cursor: pointer;
            height: 30px;
        }

        &.active {
            background-color: #fff;
            border: 1px solid #4f8bdb;
            color: #59a2eb;
        }
    }
}