.scheduler-wrapper {
    padding-bottom: 1rem;
    .menu-image {
        height: 150px;
        object-fit: cover;
    }
    .ant-card .ant-card-body {
        padding: 1rem !important;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }

    .schedules-list-wrapper {
        margin-top: 0.5rem;
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
    }

    .schedule-item {
        display: flex;
        align-items: center;
        gap: 0.5rem;
    }

    .slot-label {
        font-weight: 600;
        color: #374151;
    }

    .slot-time {
        margin-left: 8px;
        color: #4b5563;
    }

    .no-slots {
        margin-top: 16px;
    }

    .action-btn {
    }
}

.delete-message-container {
    display: flex;
    align-items: flex-start;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ffa39e;
    font-size: 12px;
    .exclamation-symbol {
        font-size: 20px;
        margin-right: 10px;
        color: #d4380d;
    }
    .note{
        margin-bottom: 0rem;
    }
}

