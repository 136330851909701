.feeds-container {
    background-color: #f2f5fe;
    min-height: calc(100vh - 55px);
    position: relative;
    display: flex;
    align-items: flex-start;
    .feedlist-container {
        margin-top: 20px;
        margin-bottom: 20px;
        // background: #ffffff;
        margin: 10px 0;
        border-radius: 8px;
        // box-shadow: rgba(37, 117, 252, 0.2) 0px 0px 5px 0px;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        flex: 1 0 55%;

        .feedlist {
            width: 100%;
            padding: 0.5rem 0.15rem;
            overflow: auto;
            height: calc(100vh - 127px);
        }
        .feedcard-container {
            background-color: #fff;
            background-position: 50%;
            background-repeat: no-repeat;
            background-size: cover;
            // border-color: #ffc800;
            border-radius: 0.75rem;
            box-shadow: 0 0 2px 0 rgba(37, 117, 252, 0.2);
            cursor: pointer;
            margin-bottom: 1rem;
            overflow: hidden;
            position: relative;
            width: 100%;
            padding: 0.25rem 0.5rem;
            width: 100%;
            &.active {
                background-color: #fede5a45;

                &::before {
                    background-color: #fede5a45;
                    // background: #fff;
                    left: 50%;
                    position: absolute;
                    content: '';
                    height: 15px;
                    transform: rotate(45deg);
                    transform: rotate(45deg) translateX(-50%);
                    width: 15px;
                    z-index: -1;
                }
            }
            &.unread {
                background: #9d9d9d57;
                color: #fff;
                outline: 2px solid #121b2b;
            }
            &:last-child {
                margin-bottom: 0;
            }
            .feed-actions-name-wrapper {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
            }
            .toggle-icon .fa {
                font-size: 1rem;
                font-weight: 600;
            }
            .feed-name,
            .view-count {
                font-size: 0.75rem;
                word-break: break-word;
                color: rgb(85, 85, 85);
                strong {
                    font-size: 0.9rem;
                }
            }
            .feed-action-container {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                .btn-icon {
                    font-size: 16px;
                    padding: 6px 12px;
                    margin-left: 0.25rem;
                }
                .pin-icon {
                    // background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAAwwAAAMMBnc7+MwAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAICSURBVFiFvdW/axNhGMDx7/OmpiZBYqGLFBGLk1CEBBWh1BYigoNbK07OWcQ/QDcXQXDsqKvRyUWEgloKQSUBKUoKQifRoViD5AeR3OOg1VPzXu7H23vG9477fni5905UFdezVOtUFHmgcP/lSv5W0L3GeR1QzBwwI3DzfK1zL3WAdHOroOsAgtwIQuwLYJjrngSZ+w0KQDgHLDzsloywBkz5120IpwBbPAjhDDAubkM4AYSNj0JI0u9A1Lh/FG4nAsSM74JugiwAHyfSjntKJdMrvNd8ryp4m7F2IEl8/Uq+6V+07sClp0z22515zXDAv+5hikZYdREHy0s4/2Tn0EQ/vwUciRCxzRdPuTAqDpZjmO3mzjiK7wbFrYDpTOEFwjsH8ZHbPhZQW2YoaAWV1n7GrQCA58uFz2K8pRiI0PFAQExEpPhYgB9h0K1x96pINUo8FADg7mzr2J3ZVvboZH87+GFeO0o8FKDRaJwFnhnh+PWZbcLshDPAXhwoAm0jXFVhMcHpCA/4Nw5cLJfLrxKcjvAAW3zvug0hQ74nBoyL/4f488X8dLBY2IgK+OtnFDbun5VHZHaGncVBvvd64/L0t9iAOHEXI6pKs9k8oapvgMNpxgFMvV7PqerjX/GvacYBTDabvQacAt6KyOk04/DzFJSB6mAwOFcqlT6kGQf4Aen8F1vBZ+4sAAAAAElFTkSuQmCC);
                    // background-position: 50%;
                    // background-repeat: no-repeat;
                    // background-size: 85%;
                    // height: 20px;
                    // margin-right: 0.5rem;
                    // width: 20px;
                    // z-index: 9999;
                    i {
                        color: tomato;
                        margin: 0 0.5rem;
                    }
                }
            }
            .feed-list-interest-wrapper {
                display: flex;
                align-items: flex-start;
                gap: 1rem;
                margin-top: 0.5rem;
            }
            .feedcontent-list {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                gap: 0.5rem;
                padding-bottom: 1.5rem;
                position: relative;
                width: 100%;
                flex: 1 0 40%;

                z-index: 9;

                .feedcontent-item {
                    background: #f1f1f1;
                    border: 1px solid #e3ebf6;
                    color: #000;
                    display: flex;
                    flex-direction: column;
                    // padding: 0.5rem;
                    align-items: center;
                    width: 6rem;
                    height: 6rem;
                    border-radius: 8px;
                    justify-content: space-between;
                    .content-actions {
                        display: flex;
                        justify-content: space-between;
                        width: 100%;
                        padding: 3px;
                        .btn {
                            border-radius: 50%;
                            white-space: nowrap;
                            margin: 0;
                            font-size: 0.75rem;
                            width: 1.25rem;
                            height: 1.25rem;
                            padding: 0;
                        }
                        .content-type {
                            font-size: 0.65rem;
                            word-break: break-word;
                            color: #000000;
                            text-transform: capitalize;
                        }
                    }

                    .feedcontent-assets {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                    }
                    img {
                        width: 100%;
                        height: 4rem;
                        object-fit: cover;
                    }
                }
            }
        }
    }
}

.createfeed-container {
    border-radius: 8px;
    font-size: 12px;
    padding: 10px;
    width: 100%;
    border: 1px solid #e5e5e5;
    color: #000;
    outline: 0;
    background: #fff;
    box-shadow: none;
    margin-bottom: 0.5rem;
    margin-top: 8px;
    background-color: #fcfcf7;
    border: 1px solid #ececec;
    box-shadow: 0 0 1px #c9c9c9;

    .form-group {
        margin-bottom: 10px;
    }
    .form-control,
    select {
        border-radius: 4px;
        font-size: 12px;
        padding: 10px;
        width: 100%;
        border: 1px solid #e5e5e5;
        color: #000;
        outline: 0;
        letter-spacing: 0.5px;
        background: #fff;
        box-shadow: none;
        min-height: 42px;
    }
    .btn {
        margin: 0;
        margin-left: auto;
        padding: 7px 15px;
        margin-right: 0.25rem;
    }
}

.feed-interest {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex: 1 0 60%;
    p {
        font-size: 0.75rem;
        word-break: break-word;
        color: #555555;
        margin-bottom: 0.5rem;
    }
    table {
        width: 100%;
        background: #ffffff;
        box-shadow: rgba(37, 117, 252, 0.2) 0px 0px 5px 0px;
        overflow: auto;
        align-items: flex-start;
        thead tr th {
            font-weight: 600;
            font-size: 13px;
            color: #121b2b;
            border: none;
            padding: 0.5rem;
            border: 1px solid #ececec;
        }
        tbody tr td {
            font-weight: 400;
            font-size: 13px;
            color: #121b2b;
            border: none;
            padding: 0.5rem;
            border: 1px solid #ececec;
        }
    }
}
.feed-preview {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.4rem 0 0;
    flex: 1 0 45%;
    input {
        border-radius: 8px;
        font-size: 12px;
        padding: 10px;
        width: 100%;
        border: 1px solid #e5e5e5;
        color: #000;
        outline: 0;
        letter-spacing: 0.5px;
        background: #fff;
        box-shadow: none;
        min-height: 42px;
        max-width: 20rem;
        margin-bottom: 0.5rem;
    }
    .mobile-view {
        background-image: url('../../assets/images/iphone_frame.png');
        width: 300px;
        height: 590px;
        display: flex;
        align-items: center;
        position: relative;
        background-size: 100% 100%;
        justify-content: center;
        margin: auto;
        iframe {
            position: absolute;
            top: 18px;
            left: 19px;
            border-radius: 26px;
            border: none;
        }

        /* width */
        ::-webkit-scrollbar {
            width: 1px;
            height: 1px;
        }

        /* Track */
        ::-webkit-scrollbar-track {
            background: #dadada;
            border-radius: 0px;
            width: 1px;
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
            background: #bbbbbb;
            border-radius: 0px;
            width: 1px;
        }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
            background: #9c9c9c;
            width: 1px;
        }
    }
}

.ck.ck-content {
    height: 7rem;
}
